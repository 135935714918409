<template>
	<div id=header>
		<div>
			<div style="height:76px;border-bottom:1px solid #000;"></div>
		</div>
		<div id=header_inner>
			<table border=0 style="width:100%" cellspacing='0px' cellpadding="0px">
				<tr >
					<td class='logo_td' onclick="location.href='/'" style="height:56px;vertical-align:bottom;border-bottom:1px solid #000;padding-bottom:5px;"><img class=logo src="/img/icon.png"></td>
					<td style="vertical-align:bottom;border-bottom:1px solid #000;padding-bottom:5px;">
						<span id=title style="text-align:left;cursor:pointer;user-select:none;" onclick="location.href='/'" >AKOM교육센터</span>
					</td>
					<td style="vertical-align:bottom">
						<ul class="tab_ul" id="tab_block">
							<li class="tab" :class="{selected:path=='/'||path=='/notice'||path=='/PDS'||path=='/FAQ'}" @click="onClickMenu2('/')">
								<span style="color:transparent">홈</span>HOME<img src='/img/new.gif' v-if="alert>0" style="position:relative;top:-5px;left:2px;"><span style="color:transparent">홈</span>
							</li>
							<li class="tab" :class="{selected:path=='/reg'||path=='/schedule'}" @click="onClickMenu('/reg')">
								My강의실<button class='alert' v-if="waiting_pay>0">{{waiting_pay}}</button>
							</li>
							<li class="tab" :class="{selected:path=='/done'||path=='/insure'||path=='/law'||path=='/certi'}" @click="onClickMenu('/done')">
								이수현황
							</li>
							<li class="tab" :class="{selected:path=='/ex_notify'||path=='/ex_upload'||path=='/ex_result'}" @click="onClickMenu('/ex_notify')" style="position:relative">
								면제/유예<img src='/img/new.gif' v-if="mesg>0" style="position:relative;top:-5px;left:2px;">
							</li>
							<li class="tab" :class="{selected:path=='/pay'}" @click="onClickMenu('/pay')">
								결제내역
							</li>
						</ul>
					</td>
					<td align="right" style="vertical-align:bottom;border-bottom:1px solid #000;padding-bottom:5px;color:#fff;font-weight: normal;">
						<!-- <i class="xi-bell-o xi-x" @click="onClickMenu('/notice')" style="position:relative"><span>공지사항</span><button class='alert' v-if="alert>0">{{alert}}</button></i>&nbsp;
						<i class="xi-help-o xi-x" @click="onClickMenu('/FAQ')" style="position:relative"><span>FAQ</span></i>&nbsp;
						<i class="xi-paper-o xi-x" @click="onClickMenu('/PDS')" style="position:relative"><span>자료실</span></i>&nbsp; -->
						<!-- <i class="xi-profile-o xi-x" @click="popup_menu()" v-if="payall==1" style="position:relative" ><span>{{username}}({{license}})</span></i> -->
						<!-- <i class="xi-profile-o xi-x" @click="popup_menu()" v-if="" style="position:relative" ><span>{{username}}({{license}})</span></i> -->
						<i class="xi-profile-o xi-x" onclick="location.href='/home'" :style="{color:payall==1?'#fff':'#E1F5A9'}" style="position:relative;margin-bottom: 5px;">
							<span style="font-size:16px">
								{{username}}({{license}})
							</span>
						</i>
						
						
						
					</td>
				</tr>
				<tr bgcolor="#fff" v-if="path!=='/pay'&&path!=='/schedule'">
					<td colspan="5" style="height:40px;position:relative;border-bottom:1px solid #ccc;background-color:#fff;">

						<div id="tab1_sub_menu" v-if="path=='/'||path=='/notice'||path=='/PDS'||path=='/FAQ'">
							<li class="sub_menu" :class="{curr_sub_menu:path=='/'}" @click="onClickMenu2('/')">교육일정</li>&emsp;|&emsp;
							<li class="sub_menu" :class="{curr_sub_menu:path=='/notice'}" @click="onClickMenu2('/notice')">공지사항<img src='/img/new.gif' v-if="alert>0" style="position:relative;top:-5px;left:2px;"></li>&emsp;|&emsp; 
							<li class="sub_menu" :class="{curr_sub_menu:path=='/FAQ'}" @click="onClickMenu2('/FAQ')">FAQ</li>&emsp;|&emsp; 
							<li class="sub_menu" :class="{curr_sub_menu:path=='/PDS'}" @click="onClickMenu('/PDS')">자료실</li>
						</div>
						<div  id="tab2_sub_menu"  v-if="path=='/done'||path=='/insure'||path=='/law'||path=='/certi'">
							<li class="sub_menu" :class="{curr_sub_menu:path=='/done'}" @click="onClickMenu('/done')">보수교육</li>&emsp;|&emsp;
							<li class="sub_menu" :class="{curr_sub_menu:path=='/insure'}" @click="onClickMenu('/insure')">보험교육</li>&emsp;|&emsp; 
							<li class="sub_menu" :class="{curr_sub_menu:path=='/law'}" @click="onClickMenu('/law')">법정교육</li>&emsp;|&emsp; 
							<li class="sub_menu" :class="{curr_sub_menu:path=='/certi'}" @click="onClickMenu('/certi')">인증서발급</li>
						</div>
						<div id="tab3_sub_menu" v-if="path=='/ex_notify'||path=='/ex_upload'||path=='/ex_result'">
							<li class="sub_menu" :class="{curr_sub_menu:path=='/ex_notify'}" @click="onClickMenu('/ex_notify')">면제/유예 제도안내</li>&emsp;|&emsp;
							<li class="sub_menu" :class="{curr_sub_menu:path=='/ex_upload'}" @click="onClickMenu('/ex_upload')">면제/유예 신청</li>&emsp;|&emsp; 
							<li class="sub_menu" :class="{curr_sub_menu:path=='/ex_result'}" @click="onClickMenu('/ex_result')">처리결과<img src='/img/new.gif' v-if="mesg>0" style="position:relative;top:-5px;left:2px;"></li>
						</div>

						<!-- <div style="position:absolute;top:10px;left:0px" v-if="my_schedules&&path=='/schedule'" >
							<li class="sub_menu" :class="{curr_sub_menu:path=='/ex_notify'}" @click="onClickMenu('/')">{{my_schedules[0].edu_title}}</li>
						</div> -->
						<div id="tab4_sub_menu" v-if="(path=='/reg'||path=='/schedule')&&waiting_pay>0" >
							<a :onclick="`location.href='/home#/schedule?sch=`+waiting_schedule+`'`">논문게제 결제대기중</a> 
						</div>
					</td>
				</tr>
				<tr bgcolor="#fff" v-else>
					<td colspan="5" style="height:40px;position:relative;background-color:#fff;"></td>
				</tr>
			</table>
			<div style="position:absolute;top:0px;width:100%;color:#fff">
				<Vue3Marquee :duration="50" style="font-size:15px;letter-spacing:1">
					<!-- [AKOM교육센터 일시중지 안내] 2023.04.18.(화) 18:00~19:00 (약 1시간) 서버 보안 작업 관련하여 일시 중지되오니 이용에 참고하여 주시기 바랍니다. -->
					<!-- ● 안내1) 4월29일(토)부터 7월9일(일)까지 시도지부보수교육이 진행되는 관계로 AKOM교육센터에서 필수과목 이수는 오는 7월10일(월)부터 가능합니다.
					&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; -->
					<!-- ● 안내) AKOM보수교육센터 홈페이지가 서버 보안 설정 작업 관계로 아래와 같이 약 50분 간 서비스가 중단되오니 양해바랍니다. 2023.05.15(월) 18:10~19:00 (약 50분간)
					&emsp;&emsp;&emsp;&emsp;&emsp;&emsp; -->
					<!-- 2023년 5월 30일 DNS서버 [AKOM교육센터 네트워크 장애안내] 네트워크가 불안정하여 서비스가 원활하지 않습니다. 복구될 때까지 조금만 기다려주세요. -->
					<!-- [AKOM교육센터 서버작업 안내] 2023.06.01.(목) 18시 10분 중앙서버 정비 관련하여 일시 (약 20~30분 소요 예정) 중지되오니 이용에 참고하여 주시기 바랍니다.  -->
					<!-- 2024년 1월 15일(월), 대한한의사협회 서버 보안 설정 작업(WAF 펌웨어 업그레이드)이 진행될 예정으로 ‘오후 20시’ 이후 AKOM 교육센터 역시 영향을 받아 사용이 원활하지 않을 수 있으니 가급적 서비스 중단 시간에는 접속을 자제하여 주시기 바랍니다. &emsp;&emsp; -->
					<!-- [ 장소변경안내 ] 2023추가보수교육(수도권역) - ⭐성균관대학교 600주년 기념관 새천년홀(지하2층)⭐ - 2024년03월17일(일) 13시 00분 ~ 17시30분 -->
				</Vue3Marquee>
			</div>
		</div>
		<div>
			<div style="height:76px;border-bottom:1px solid #000;"></div>
		</div>
		<div class="bg" v-show="popup=='on'" style="background-color:transparent;position:absolute;position:absolute;left:0px;top:0px;bottom:0px;width:100%" @click="popup_menu()"></div>
		<div class="bg2" v-show="popup2=='on'" @mousewheel="prevent_wheel"  style="background-color:transparent;position:absolute;position:absolute;left:0px;top:0px;bottom:0px;width:100%"></div>
	</div>
</template>

<script>

import axios from 'axios';
import { Vue3Marquee } from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'

export default {
	// props:['edu_code','all_code'],
	name: "App",
	components: {
		
	},
	data: function () {
		return {
			path:'/',
			popup:'off',
			popup2:'off',
			sub_title:'',
			my_schedules:'',
			schedule_id:'',
			license:'login',
			username:'로그인',
			payall:1,
			router:'open',
			alert:0,
			selected_tab:'home',
			mesg:0,
			path:'',
			waiting_pay:0,
			waiting_schedule:''
		};
	},
	watch:{
		$route(to, from) {
			this.path = to.path
			this.schedule_id = to.query.sch
			// if(this.schedule_id){
			// 	this.load_schedule_name()
			// }
		}
	},

	mounted(){
		this.emitter.on('close_menu',(obj)=>{
			this.popup='off'
		})
		this.emitter.on('modify_alert',(obj)=>{
			this.load_alert()
		})
		this.emitter.on('modify_mesg',(obj)=>{
			this.load_mesg()
		})
		this.load_alert()
		this.load_mesg()
		this.load_waiting_pay()
	},
	methods: {
		load_mesg(){
			this.mesg = 0
			axios
			.post('/edu/load_mesg')
			.then((response)=>{
				this.mesg = response.data
			})
		},
		load_alert(){
			this.alert = 0
			axios
			.post('/edu/load_alert')
			.then((response)=>{
				this.alert = response.data
			})
		},
		load_waiting_pay(){
			axios
			.post('/edu/load_waiting_pay')
			.then((response)=>{
				this.waiting_pay = response.data.cnt
				this.waiting_schedule = response.data.schedule_id
			})
		},
		// load_schedule_name(){
		// 	axios
		// 	.post('/mypage/load_schedule_name',{
		// 		schedule_id:this.schedule_id
		// 	})
		// 	.then( response=>{
		// 		this.my_schedules = response.data
		// 	});
		// },
		onClickMenu(U){
			location.href = '/home'
		},
		onClickMenu2(U){
			this.path = U
			this.$router.push(U)
		},
		popup_menu(){
			if(this.popup == 'on'){
				this.popup = 'off'
			}else{
				this.popup = 'on'
				setTimeout(()=>{
					var ww = document.querySelector('#header_inner').clientWidth
					document.querySelector('.bg').style.width = ww + 'px'
					document.querySelector('.bg2').style.width = ww + 'px'
				},100)
			}
			this.emitter.emit('popup_menu',this.popup)
		}
		
	},
	
}
</script>

<style scoped>
.sub_menu{
	font-family:'Play','Noto Sans KR';
	display:inline-block;
	cursor: pointer;
}

#header_inner{
	position: relative;
	width: 100%;
	/* min-width:1400px; */
	padding-top:20px;
	background-color: #555;
}
#tab_block{
	display:none;
}
#tab1_sub_menu{
	position:absolute;top:10px;left:10px
}
#tab2_sub_menu{
	position:absolute;top:10px;left:10px
}
#tab3_sub_menu{
	position:absolute;top:10px;left:10px
}
#tab4_sub_menu{
	position:absolute;top:10px;left:10px
}


@media (min-width:1050px) {
	#tab_block{
		display:block;
	}
	#tab1_sub_menu{
		position:absolute;top:10px;left:285px
	}
	#tab2_sub_menu{
		position:absolute;top:10px;left:455px
	}
	#tab3_sub_menu{
		position:absolute;top:10px;left:560px
	}
	#tab4_sub_menu{
		position:absolute;top:10px;left:425px
	}
}

.tab_ul{
	background-color: #555;
	border-bottom:1px solid #000;
	padding:0px;
	margin-bottom: 0px;
}
.tab{
	list-style:none;
	display:inline-block;
	margin-bottom:-1px;
	border:1px solid #333;
	border-bottom:1px solid #000;
	background-color: #777;
	color: #eee;
	cursor:pointer;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	width: 140px;
	height:40px;
	padding:10px 15px 0px 15px;
	text-align: center;
	user-select: none;
	font-size:15px;
}
.selected{
	background-color: #fff;
	border-color: #555;
	border-bottom-color:#fff;
	color: #000;
	font-weight: bold;

}
.curr_sub_menu{
	font-weight: bold;
}
.alert{
	position: relative;
	left:0px;
}
</style>
