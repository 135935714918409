import { createApp } from 'vue/dist/vue.esm-bundler';
import { createWebHashHistory, createRouter } from "vue-router";
import mitt from 'mitt'
import Vue3Marquee from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'

import App 			from './vue/open/root';
import notice 		from './vue/open/notice';
import time_table 	from './vue/open/time_table';



var empty = {template:'<div></div>'}

const routes = [
	{ path: '/', 								component:time_table },
	{ path: '/notice', 		name:'notice',		component:notice },
	{ path: '/FAQ', 		name:'FAQ',			component:notice },
	{ path: '/PDS', 		name:'PDS',			component:notice },

	
]

const router = createRouter({
	history:createWebHashHistory(),
	scrollBehavior(to, from, SavedPosition) {
		return SavedPosition||{top:0};
	},
	routes
})

const emitter = mitt()
const app = createApp(App).use(Vue3Marquee)
app.config.globalProperties.emitter = emitter
app.use(router)
app.mount('#root')