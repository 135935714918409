<template>
	<div id="popup" v-if="schedule.id">
		<div id="inner_popup" ref="inner_popup">
			<div style="width:100%;text-align:right">
				<i class="xi-close-circle xi-3x" @click="close" style="margin-right:-2px;margin-top:2px"></i>
			</div>

			<div id="contents">

				<h1>{{schedule.edu_title}}</h1>

				

				<div style="width:100%;margin-bottom:20px;text-align:center;font-size:17px">



					<!-- 퇴실확인 QR코드 -->
					
					<div v-if="payed==1&&schedule.edu_place!=='AKOM교육센터'&&toYMDHS3<web_close_date">
						<!-- 조건 : !AKOM교육센터 && inPlaceTime && !outplacetime-->
						<div v-if="inPlaceTime&&!outPlaceTime&&toYMD<parseInt(dateAdd(web_close_date, 2))+100" style="width:100%;text-align:center;margin-bottom:30px;">
							<br>
							<div v-if="timer>0" style="line-height:1;display:inline-block;width:fit-content;height:200px">
								<img :src="`/qrcode?sch=${schedule.id}&${time_key}`" class="qrcode">
								<br>
								<a @click="pre_reduce_time" style="font-size:13px">{{timer}}</a>
							</div>
							<div v-else style="display:inline-block;width:150px;">
								<button class="qr_btn" @click="pre_reduce_time">퇴실 확인용<br>QR코드 불러오기</button>
							</div>
						</div>
					</div>





					<!-- 참석확인증-->
					<!-- 조건 : !AKOM교육센터 && inPlaceTime && outplacetime-->
					<div v-if="payed==1&&schedule.edu_place!=='AKOM교육센터'&&inPlaceTime&&outPlaceTime&&(schedule.register_card_yn==1||schedule.approval==1)" style="display:grid;justify-items:center;font-size:14px;text-align:left;margin-bottom:20px">
						<br>
						<fieldset style="width:100%;max-width:400px;font-size:16px;">
							<legend><b style="font-size:18px;">[ 참석확인증 ]</b></legend>
							<table cellspacing='10px' style="table-layout:fixed;width:100%">
								<colgroup>
									<col width="63px"><col width="10px">
								</colgroup>
								<tr><td>발행번호</td><td>:</td><td style="letter-spacing:-1px;">{{schedule.id}}_{{schedule.license}}</td></tr>
								<tr><td>성명</td><td>:</td><td>{{schedule.name_kor}}</td></tr>
								<tr><td>근무처명</td><td>:</td><td>{{schedule.office}}</td></tr>
								<tr><td>소속지부</td><td>:</td><td>{{find_code_name('local_code',schedule.local_code,'short')}}/{{find_code_name('branch_code',schedule.branch_code,'')}}</td></tr>
								<tr><td>교육명</td><td>:</td><td>{{schedule.edu_title}}</td></tr>
								<tr><td>교육일자</td><td>:</td><td>{{schedule.web_open_date==schedule.web_close_date?dateComma(schedule.web_close_date):''+dateComma(schedule.edu_date)}}</td></tr>
								<tr><td>입실시간</td><td>:</td><td>{{inPlaceTime}}</td></tr>
								<tr><td>퇴실시간</td><td>:</td><td>{{outPlaceTime}} ({{cal_time(inPlaceTime,outPlaceTime)}}시간)</td></tr>
								<tr><td>취득평점</td><td>:</td><td>{{getpoint}}</td></tr>
								<tr>
									<td colspan="3" align="center" >
										<br>
										<b style="font-size: 20px;">{{schedule.edu_organ_code=='AK0010000'||schedule.edu_organ_code=='AK0260131'?'대한한의사협회':find_edu_name('edu_organ',schedule.edu_organ_code)}}</b>[직인생략]
									</td>
								</tr>
							</table>
						</fieldset>
					</div>

					<!-- 조건 : !AKOM교육센터 && inPlaceTime && outplacetime-->
					<div v-if="payed==1&&schedule.edu_place!=='AKOM교육센터'&&inPlaceTime&&outPlaceTime&&schedule.register_card_yn!==1&&schedule.approval!==1">
						<h2 style="color:gray;text-align: center;">
							수강시간을 계산하고 있습니다.
						</h2>
						입실 : {{inPlaceTime.substr(0,inPlaceTime.length-3)}} ~ 
						퇴실 : {{outPlaceTime.substr(0,outPlaceTime.length-3)}} ({{cal_time(inPlaceTime,outPlaceTime)}}시간)<br>
						예상점수 : {{getpoint}}
					</div>


					<div v-if="schedule.edu_item_code!=='0007'" class='ck_root' v-html="schedule.contents" style="width:100%;text-align:justify;"></div>



				
					<!-- 수강료 표-->
					<!-- 조건 : (pay_way=='Allin1'||schedule.edu_item_code=='0012')&&payed==0&&opt_cnt>0"  -->

					<div v-if="schedule.edu_item_code!=='0007'">
						<div v-if="schedule.web_close_ymdhs>toYMDHS">
							<div v-if="rcpt_way!=='self'
								&&payed==0
								&&schedule.edu_year == '2019'
								&&schedule.edu_item_code=='0022'
								&&schedule.edu_organ_code=='AK0260131'
								&&chuna>0" 
								style="text-align:center;font-size:17px"
							>
								<h3 style="color:#555">* 이미 이수하셨습니다.</h3>
								<button class='submit_btn' @click="add_my_schedule()">입장</button>
							</div>
							<div v-else-if="rcpt_way!=='self'&&payed==0&&(pay_way=='Allin1'||schedule.edu_item_code=='0012')" style="text-align:center;font-size:17px;padding-bottom:200px;">
								<br>
								{{period}}
								<br>
								<div v-if="schedule.edu_item_code=='0012'&&schedule.edu_place=='AKOM교육센터'">

								</div>
								<div v-else>
									<select v-if="(schedule.edu_item_code=='0012'&&schedule.edu_place!=='AKOM교육센터')" 
										style="vertical-align:0px;height:50px;font-size:15px;margin-right:2px;"
										@change="change_multi"
									>
										<option v-for="i,k in edu_time_point" :key="k" :value=i :selected="edu_time_point==i">{{i}} 평점</option>
									</select>
									<div v-if="opt_cnt>0" class="select" data-role="selectBox" @click="onClickSelectBox()" >
										<span date-value="optValue" class="selected-option" style="display:grid;grid-template-columns:1fr 120px;" >
											<div v-show='selected' style="text-align:left">{{selected.grade}}</div> 
											<div v-show='selected' style="text-align:right;padding-right:20px">{{comma(selected.won)}} 원</div>
										</span>
										<ul v-if="hideNshow=='show'">
											<li v-for="t,k in price" :key="'t'+k" 
												v-show="t.grade&&t.use!=='false'" 
												@click="onSelectThis(t)" 
												style="width:100%;display:grid;grid-template-columns:1fr 1fr;" 
												:class="{'disabled':t.use!==myside||t.grade!==mygrade}"
											>
												<div style="text-align:left">{{t.grade}}</div>
												<div style="text-align:right;padding-right:0px">{{comma(t.won)}} 원</div>
											</li>
										</ul>
									</div> 
								</div>
								<br>
								<span v-if="com_start">
									<button v-if="toYMDHS>pre_start&&toYMDHS<pre_end" class='submit_btn' @click="add_my_schedule()" title="등록1-1">등록</button>
									<button v-else-if="pre_start=='0000'" class='submit_btn' @click="add_my_schedule()" title="등록1-2">등록</button>
									<button v-else class='submit_btn' style="background-color:#ccc;"  title="등록1-3">등록</button>	
								</span>
								<button v-else class='submit_btn' @click="add_my_schedule()" title="등록3">등록</button>

							</div>
							<div v-else-if="rcpt_way!=='self'&&payed==0&&pay_way=='toMax'" style="text-align:center;font-size:17px">
								<!-- {{payed}}/{{pay_way}}/{{schedule.edu_item_code}}/{{opt_cnt}} -->
								<button class='submit_btn' @click="add_my_schedule()" title="등록4">등록</button>
							</div>
							<div v-else-if="rcpt_way!=='self'&&payed==0&&schedule.edu_item_code!=='0007'">
								<h3 style="color:red">* 해당보수교육 대상이 아님1</h3>
							</div>
						</div>
						<div v-else >
							<!-- {{toYMDHS}} {{web_close_date}} -->
							<h3 style="color:#555">행사종료</h3>
						</div>
					

				
						<!-- 등록안내  -->
						<div v-if="payed==1&&schedule.edu_place!=='AKOM교육센터'" style="text-align:center;font-size:17px;padding-bottom:50px;">
							<br>
							<div>
								<div style="line-height:2;">
									<br>
									<h2 v-if="rcpt_way=='self'">자체수납</h2>
									<h2 v-else-if="web_open_date.substr(0,8)>toYMDHS2.substr(0,8)" >사전등록 되었습니다.</h2>
									<!-- <h2 v-else-if="web_open_date>toYMDHS2" >사전등록 되었습니다.</h2> -->
									<div v-else-if="!inPlaceTime&&toYMDHS<schedule.web_close_ymdhs">
										<h2 style="margin-bottom:0;">접수대기중</h2>
										<!-- <button type="button" class="btn btn-primary" style="width:100px;padding:10px;" @click="self_inplace_time()">Self접수</button>
										<br>
										<br> -->
									</div>
									<span style="color:blue">장소 :&nbsp;&nbsp;<b>{{schedule.edu_place}}</b></span><br>
									@기간 :&nbsp;&nbsp;{{dateComma2(web_open_date)}} ~ {{dateComma2(web_close_date).replace(dateComma2(web_open_date).substr(0,13),'')}} ({{cal_period(web_open_date,web_close_date)}})<br><br>
									<span style="color:gray">※ 자세한 문의사항은 <b>{{find_edu_name('edu_organ',schedule.edu_organ_code)}}</b> 로 연락하시길 바랍니다.</span>
								</div>
							</div>
							<!-- <button v-else class='submit_btn' @click="add_my_schedule()">등록2</button> -->
							<br>
							<br>
						</div>
						<!-- <button v-else-if="schedule.web_close_ymdhs>toYMDHS" class='submit_btn' style="background-color:green" @click="goSchedule(schedule.id)">바로가기</button>
						<button v-else class='submit_btn' style="background-color:gray">기한종료</button> -->
						<!-- 자체수납 -->
						<!-- <div v-else-if="rcpt_way=='self'" style="text-align:center;font-size:17px;margin-top:50px;padding-bottom:50px;">
							<div >
								<h3>자체수납</h3>
								<span style="color:blue">장소 :&nbsp;&nbsp;<b>{{schedule.edu_place}}</b></span><br>
								기간 :&nbsp;&nbsp;{{dateComma2(web_open_date)}} ~ {{dateComma2(web_close_date).replace(dateComma2(web_open_date).substr(0,13),'')}} ({{cal_period(web_open_date,web_close_date)}})<br><br>
								
								<span style="color:gray">※ 자세한 문의사항은 <b>{{find_edu_name('edu_organ',schedule.edu_organ_code)}}</b> 로 연락하시길 바랍니다.</span>
							</div>
						</div> -->
					
						

						<!-- 돈을 냈고 입장기록도 없고 나온 기록도 없다.   -->
						<div v-else-if="payed==1&&!inPlaceTime&&!outPlaceTime" style="text-align:center;font-size:17px">
							<!-- <div v-else-if="!open_able&&inPlaceTime&&!outPlaceTime">
								<div v-if="schedule.edu_place!=='AKOM교육센터'" style="line-height:2;">
									<h3 v-if="edu_ok_yn" style="color:gray">수강완료</h3>
									<h3 v-else style="color:gray">기한종료</h3>
								</div>
							</div> -->
								<!-- <div v-else>
									<h3 v-if="payed==1">등록되었습니다.</h3>
									기간 :&nbsp;&nbsp;{{dateComma2(web_open_date)}} ~ {{dateComma2(web_close_date).replace(dateComma2(web_open_date).substr(0,13),'')}} ({{cal_period(web_open_date,web_close_date)}})<br>
									<span style="color:blue">장소 :&nbsp;&nbsp;<b>{{schedule.edu_place}}</b></span><br><br>
									<span style="color:gray">※ 자세한 문의사항은 <b>{{find_edu_name('edu_organ',schedule.edu_organ_code)}}</b> 로 연락하시길 바랍니다.</span>
								</div> -->
							<!-- 닫힘 입장기록도 없고 나온 기록도 없다. -->

							<!-- <div v-if="!inPlaceTime">
								<h3 v-if="payed==1">접수되었습니다.</h3>
								<h3 v-if='!open_able&&toYMD>parseInt(dateAdd(web_close_date, 2))' style="color:gray">기한종료</h3>

								기간 :&nbsp;&nbsp;{{dateComma2(web_open_date)}} ~ {{dateComma2(web_close_date).replace(dateComma2(web_open_date).substr(0,13),'')}} ({{cal_period(web_open_date,web_close_date)}})<br>
								<span style="color:blue">장소 :&nbsp;&nbsp;<b>{{schedule.edu_place}}</b></span><br><br>
								<span style="color:gray">※ 자세한 문의사항은 <b>{{find_edu_name('edu_organ',schedule.edu_organ_code)}}</b> 로 연락하시길 바랍니다.</span>
							</div> -->

							<div v-if='!open_able&&toYMD>parseInt(dateAdd(web_close_date, 2))' style="color:gray"><h3 >기한종료</h3></div>
							<div v-else-if="rcpt_way!=='self'&&!target"><h3 style="color:red">* 해당보수교육 대상이 아님2</h3></div>
							<div v-else>
								<div v-if="schedule.edu_item_code=='0002'" >
									<h3 v-if="payed==1">등록되었습니다.</h3>
									<h3 v-else style="color:green">현장 등록만 가능</h3>
								</div>
							</div>
						</div>
						<div v-else-if="schedule.edu_place!=='AKOM교육센터'">
							<h3 v-if="edu_ok_yn" style="color:gray">수강완료</h3>
							<h3 v-if="toYMD>parseInt(dateAdd(web_close_date, 2))" style="color:gray">종료되었습니다.</h3>
							<!-- <h2 v-if="payed==1&&schedule.edu_place!=='AKOM교육센터'&&web_open_date>toYMDHS2" >사전등록 되었습니다.</h2> -->
							<!-- {{web_open_date}}
							<br>
							{{toYMDHS2}}
							<br>
							{{payed}} -->

							<span style="color:blue">장소 :&nbsp;&nbsp;<b>{{schedule.edu_place}}</b></span><br>
							#기간 :&nbsp;&nbsp;{{dateComma2(web_open_date)}} ~ {{dateComma2(web_close_date).replace(dateComma2(web_open_date).substr(0,13),'')}} ({{cal_period(web_open_date,web_close_date)}})<br><br>
							<span style="color:gray">※ 자세한 문의사항은 <b>{{find_edu_name('edu_organ',schedule.edu_organ_code)}}</b> 로 연락하시길 바랍니다.</span>
						</div>

					</div>
					<!-- end	 -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>

var dt = new Date;
import axios from 'axios';
// import DivStudypaper from './studypaper.vue'


// var test = 300;
// function reduce_time(){
// 	test--;
// 	console.log(test)
// 	setTimeout(()=>{
// 		reduce_time()
// 	},1000)
// }

export default {
	props:['edu_code','all_code'],
	name: "App",
	components: {
		// DivStudypaper
	},
	data: function () {
		return {
			schedule:'',
			payed:9999,
			toYear:dt.getFullYear(),
			toDay:dt.getFullYear()+('0'+String(dt.getMonth()+1)).slice(-2)+('0'+String(dt.getDate())).slice(-2),
			toYMDHS:dt.getFullYear()+('0'+String(dt.getMonth()+1)).slice(-2)+('0'+String(dt.getDate())).slice(-2)+('0'+String(dt.getHours())).slice(-2)+('0'+String(dt.getMinutes())).slice(-2),
			toYMDHS2:dt.getFullYear()+('0'+String(dt.getMonth()+1)).slice(-2)+('0'+String(dt.getDate())).slice(-2)+('0'+String(dt.getHours()+1)).slice(-2)+('0'+String(dt.getMinutes())).slice(-2),
			toYMDHS3:dt.getFullYear()+('0'+String(dt.getMonth()+1)).slice(-2)+('0'+String(dt.getDate())).slice(-2)+('0'+String(dt.getHours()-3)).slice(-2)+('0'+String(dt.getMinutes())).slice(-2),
			toYMD:dt.getFullYear()+('0'+(dt.getMonth()+1)).slice(-2)+('0'+(dt.getDate())).slice(-2),
			hideNshow:'hide',
			selected:'',
			pay_way:'',
			price:'',
			opt_cnt:0,
			myside:'',
			mygrade:'',
			target:false,
			period:'일반기간',
			open_able:false,
			web_open_date:'',
			web_close_date:'',
			time_key:Date.now(),
			timer:0,
			inPlaceTime:'',
			outPlaceTime:'',
			edu_ok_yn:'',
			rcpt_way:'',
			chuna:'',
			// chuna_payed:'',
			fixwon:'',
			price_fix:'',
			multi:1,
			edu_time_point:'',
			timeoutHandle:'',
			getpoint:'',
			com_start:'',
			com_end:'',
			pre_start:'',
			pre_end:''
		};
	},
	unmounted() {
		this.emitter.off('popup_schedule_info');
	},
	mounted(){
		
		// this.reduce_time()
		this.emitter.on('popup_studypaper_close',(obj)=>{
			this.close()
		})

		this.emitter.on('popup_schedule_info',(obj)=>{
			
			// console.log(obj)

			this.getpoint = `평점:${obj.schedule.get_point}점 , 필수:${obj.schedule.get_duty_point}점`
			this.edu_time_point = obj.schedule.edu_time_point
			this.inPlaceTime = obj.schedule.start_date
			this.outPlaceTime = obj.schedule.end_date
			this.edu_ok_yn = obj.schedule.edu_ok_yn
			this.hideNshow='hide'
			this.selected = '',
			this.myside = '',
			this.mygrade = '',
			this.opt_cnt = 0
			this.target = false
			this.chuna = obj.selected.chuna
			// this.chuna_payed = obj.selected.chuna_payed
			this.timer = 0
			
			axios
			.post('/edu/load_detail_schedule',{
				id:obj.schedule.id,
				item_code:obj.schedule.edu_item_code
			})
			.then((response)=>{

				// console.log(response.data[0])

				this.schedule = response.data[0]
				this.schedule['web_close_ymdhs'] = obj.schedule.web_close_date+obj.schedule.web_close_time||'0000'
				// this.rcpt_way = response.data[0].rcpt_way;
				// console.log(this.rcpt_way)
				this.opt_cnt = obj.opt_cnt
			})

			
			var array = JSON.parse(obj.schedule.price)
			var price = []
			array?.forEach(el => {
				if(this.toYMDHS>=obj.schedule.pre_reg_start&&this.toYMDHS<=obj.schedule.pre_reg_end){
					var regx = new RegExp(this.toYear+'년', "g")
					this.period = `사전등록기간 : ${this.dateComma2(obj.schedule.pre_reg_start).replace(regx,'')} ~ ${this.dateComma2(obj.schedule.pre_reg_end).replace(regx,'')}`
					el.won = el.won1
					obj.selected.won = obj.selected.won1
				}else{
					if(obj.schedule.reg_start){
						if(obj.schedule.edu_place!=='AKOM교육센터'){
							this.period = `현장등록 : ${this.dateComma2(obj.schedule.reg_start)} ~ ${this.dateComma2(obj.schedule.reg_end)}`
						}else{
							this.period = `일반등록기간 : ${this.dateComma2(obj.schedule.reg_start)} ~ ${this.dateComma2(obj.schedule.reg_end)}`
						}
					}else{
						this.period = ''
					}
					obj.selected.won = obj.selected.won2
					el.won = el.won2
				}
				price.push(el)
			});
			// console.log(price)

			this.web_open_date = obj.schedule.web_open_date+obj.schedule.web_open_time||'0000'
			this.web_close_date = obj.schedule.web_close_date+obj.schedule.web_close_time||'0000'
			var total_start = obj.schedule.web_open_date+obj.schedule.web_open_time||'0000'
			var total_close = obj.schedule.web_close_date+obj.schedule.web_close_time||'0000'
			var pre_start = (obj.schedule.pre_reg_start+'0000').substr(0,12);
			var pre_end = (obj.schedule.pre_reg_end+'0000').substr(0,12);
			var com_start = (obj.schedule.reg_start+'0000').substr(0,12);
			var com_end = (obj.schedule.reg_end+'0000').substr(0,12);


			this.com_start = com_start
			this.com_end = com_end
			this.pre_start = pre_start
			this.pre_end = pre_end

			// console.log(1,this.open_able)
			if(this.toYMDHS<total_start||this.toYMDHS>total_close){ //행사종료
				this.open_able = false
			}else{
				this.open_able = true
			}
			// console.log(this.toYMDHS,total_close,obj.schedule.web_close_time)
			// console.log(2,this.open_able)
			// console.log(this.toYMDHS,pre_start,pre_end)
			if(obj.schedule.pre_reg_start){ //사전등록기간
				if(this.toYMDHS<pre_start||this.toYMDHS>pre_end){

					this.open_able = false
					console.log(3,this.open_able,obj.schedule.reg_start)
					if(obj.schedule.reg_start){
						if(this.toYMDHS<com_start||this.toYMDHS>com_end){
							this.open_able = false
						}else{

							console.log('일반등록기간')
							this.open_able = true
						}
					}
				}else{
					console.log('사전등록기간')
					this.open_able = true
				}
			}
			

			if(obj.schedule.rcpt_way=='self'){
				this.open_able = false
			}
			// console.log(obj.selected)
			this.price = price
			this.price_fix = obj.schedule.price
			this.payed = obj.selected.payed
			this.myside = obj.selected.side
			this.mygrade = obj.selected.grade
			this.selected = obj.selected
			this.fixwon = obj.selected.won
			this.pay_way = obj.schedule.pay_way
			this.rcpt_way = obj.schedule.rcpt_way

			if(obj.schedule.target == 'all'){
				this.target = true
			}
			if(obj.schedule.target == 'only' && obj.selected.side!=='outside'){
				this.target = true
			}
			if(obj.selected.payed==1){
				this.target = true
			}
			if(obj.schedule.target == 'only' && obj.selected.side=='outside'){
				this.target = false
			}
			// if(obj.schedule.edu_item_code=='0012' && obj.selected.miss==0){
			// 	this.target = false
			// }
			// if(obj.schedule.edu_item_code=='0022' && obj.schedule.edu_organ_code=='AK0260131' && this.chuna>0){
			// 	this.target = false
			// }
			// if(obj.schedule.edu_item_code=='0023' && obj.schedule.edu_organ_code=='AK0260130' && this.chuna_payed>0){
			// 	this.target = false
			// }

			// console.log(this.target,obj.selected.side)

			document.querySelector('#main_wrap').scrollLeft = '0px'
			document.querySelector('#main_wrap').style.overflowX = 'hidden'

			
			if(obj.schedule.edu_item_code=='0012'&&obj.schedule.edu_place!=='AKOM교육센터'){
				this.change_multi2(obj.schedule.edu_time_point)
			}

			// if(obj.selected.payed==1 && obj.schedule.edu_place=='AKOM교육센터' && this.web_close_date > this.toYMDHS ){
				
			// 	this.goSchedule(obj.schedule.id)
			// 	setTimeout(()=>{
			// 		this.close()
			// 	},500)
				
			// }

		})
	},
	methods: {
		self_inplace_time(){
			axios
			.post('/edu/self_inplace_time',{
				schedule:this.schedule.id
			})
			.then( response=>{
				// history.pushState(null, null, 'home#/reg?sche='+this.schedule.id)
				// location.reload()
			});
		},
		change_multi(e){
			this.multi = e.target.value
			this.selected.won = parseInt(this.fixwon.replace(/[^0-9]/g,''))*e.target.value
			var fix = []
			var array = JSON.parse(this.price_fix)
			array.forEach(el => {
				if(this.toYMDHS>=this.schedule.pre_reg_start&&this.toYMDHS<=this.schedule.pre_reg_end){
					el.won = el.won1
				}else{
					el.won = el.won2
				}
				fix.push(el)
			});
			var price = []
			fix.forEach(el=>{
				var obj = el
				obj.won = parseInt(String(el.won).replace(/[^0-9]/g,'')||0)*e.target.value
				price.push(obj)
			})
			this.price = array;
		},
		change_multi2(M){
			this.multi = M
			this.selected.won = parseInt(this.fixwon.replace(/[^0-9]/g,''))*M
			var fix = []
			var array = JSON.parse(this.price_fix)
			array.forEach(el => {
				if(this.toYMDHS>=this.schedule.pre_reg_start&&this.toYMDHS<=this.schedule.pre_reg_end){
					el.won = el.won1
				}else{
					el.won = el.won2
				}
				fix.push(el)
			});
			var price = []
			fix.forEach(el=>{
				var obj = el
				obj.won = parseInt(String(el.won).replace(/[^0-9]/g,'')||0)*M
				price.push(obj)
			})
			this.price = array;
		},
		dateAdd(date, addDays) {
			var datetmp = date.replace(/-/g,'');			// - 는 모두 제거
			var y = parseInt(datetmp.substr(0, 4));
			var m = parseInt(datetmp.substr(4, 2));
			var d = parseInt(datetmp.substr(6,2));
			d = new Date(y, m - 1, d + addDays);
			y = d.getFullYear();
			m = d.getMonth() + 1; m = (m < 10) ? '0' + m : m;
			d = d.getDate(); d = (d < 10) ? '0' + d : d;
			// console.log(y+('0'+m).slice(-2)+('0'+d).slice(-2))
			return y+('0'+m).slice(-2)+('0'+d).slice(-2)
		},

		pre_reduce_time(){
			this.timer = 300
			clearTimeout( this.timeoutHandle )
			this.reduce_time()
		},

		reduce_time(){
			if(this.timer <= 0){
				clearTimeout( this.timeoutHandle )
				return false;
			}
			if(this.timer%11==0){
				axios
				.post('/edu/return_qr_scan',{
					schedule:this.schedule.id
				})
				.then( response=>{
					if(response.data[0].register_card_yn==1){
						history.pushState(null, null, 'home#/reg?sche='+this.schedule.id)
						location.reload()
						this.close()
					}
				});
			}
			this.timer--
			this.timeoutHandle = setTimeout(()=>{
				this.reduce_time()
			},1000)
			
		},
		dateComma(str){
			return dateComma(str,'-')
		},
		cal_period(A,B){
			var a = dateComma(A.substr(0,8),'-')
			var b = dateComma(B.substr(0,8),'-')
			var day1 = new Date(a);
			var day2 = new Date(b);
			var difference = day2.getTime()-day1.getTime();
			return difference<0?'오류':difference/(1000*3600*24)+1+'일간'
		},
		find_edu_name(F,C){
			if(this.edu_code[F+'_'+C]){
				return this.edu_code[F+'_'+C].code_name
			}else{
				return '';
			}
		},
		goSchedule(URL){
			this.close()
			this.$router.push('/schedule?sch='+URL)
		},
		close(){
			clearTimeout( this.timeoutHandle )
			this.schedule = ''
			this.emitter.emit('close');
			document.querySelector('#main_wrap').style.overflowX = 'auto'
		},
		add_my_schedule(){
			
			if(this.payed==1){
				axios
				.post('/edu/add_my_schedule',{
					schedule:this.schedule
				})
				.then( response=>{
					location.reload()
				});

			}else if((this.pay_way=='Allin1' || ( this.schedule.edu_item_code=='0012' && this.schedule.edu_place!=='AKOM교육센터') ) && this.opt_cnt>0 && parseInt(String(this.selected.won).replace(/[^\d]/g,'')) > 0){

				this.requestPay()
				return false
			
			}else{
				axios
				.post('/edu/add_my_schedule',{
					schedule:this.schedule
				})
				.then( response=>{
					location.reload()
				});
			}
		},
		requestPay: async function () {

			document.getElementById("popup").style.display = "none";

			const {data} = await axios({
				url: "/payment/buyerinfo",
				method: "post", // POST method
				headers: { "Content-Type": "application/json" },
				data:{
					price:parseInt(String(this.selected.won).replace(/[^0-9]/g,'')),
					schedule:this.schedule
				}
			})

			// if(data=='reload'){
			// 	alert('회비납부 변경내역이 있습니다. 페이지를 다시 불러옵니다. ')
			// 	location.reload()
			// 	return false
			// }
			

			if(!data.buyertel?.replace(/[^0-9]/g,'')){
				var temptel = prompt('연락가능한 휴대폰번호를 입력해주세요(숫자만)')
				if(!temptel){
					alert('연락처는 필수항목입니다.')
					location.reload()
					return false
				}
			}

			
			var query = `sch=${this.schedule.id}`
			query += `&lec=${this.lecture_id}`
			query += `&license=${data.license}`
			query += `&kind=schedule`
			query += `&price=${data.price}`
			// var buyer_addr = userinfo.data.office_addr1+' '+userinfo.data.office_addr2
			// var buyer_postcode = userinfo.data.local_code


			var merchantData = {
				sch:this.schedule.id,
				lec:this.lecture_id||'',
				license:data.license,
				name:data.name,
				mobile:data.buyertel?.replace(/[^0-9]/g,'')||temptel,
				email:data.buyeremail,//data.buyeremail,
				fee_grade_code:data.fee_grade_code,
				edu_point:this.schedule.edu_time_point,
				duty_point:this.schedule.edu_must_point,
				addr:data.addr,
				office:data.office,
				office_tel:data.office_tel,
				local_code:data.local_code,
				branch_code:data.branch_code,
				kind:'schedule',
				period:this.period,
				place:this.schedule.edu_place=='AKOM교육센터'?'akom':'out',
				multi:this.multi,
				subject:this.schedule.edu_subject
			}

			
			var array ={
				version:'1.0',
				gopaymethod:'Card:VBank',
				mid:data.mid,
				oid:data.oid,
				price:data.price,
				timestamp:data.timestamp,
				currency:'WON',
				goodname:this.schedule.edu_title,
				buyername:data.buyername,
				buyertel:data.buyertel?.replace(/[^0-9]/g,'')||temptel,
				buyeremail:data.buyeremail,
				returnUrl:'https://edu.akom.org/payment/return?'+query,
				closeUrl:'https://edu.akom.org/payment/close?'+query,
				signature:data.sign,
				mKey:data.mKey,
				P_INI_PAYMENT:'CARD',
				P_MID:data.mid,
				P_OID:data.oid,
				P_AMT:data.price,
				P_GOODS:this.schedule.edu_title,
				P_UNAME:data.buyername,
				P_MOBILE:data.buyertel?.replace(/[^0-9]/g,'')||temptel,
				P_EMAIL:data.buyeremail,
				P_NEXT_URL:'https://edu.akom.org/payment/mobile',
				merchantData:encodeURI(JSON.stringify(merchantData)),
				quotabase:"3:4:5:6",
				P_QUOTABASE:"03:04:05:06",
				P_NOTI:encodeURI(JSON.stringify(merchantData)),
				acceptmethod:`SKIN(#084B8A):vbank(${data.limit}):noeasypay`
			}


			document.querySelector('#SendPayForm_id')?.remove()

			var payForm = document.createElement('form');
			payForm.setAttribute('method', 'post');
			payForm.setAttribute('action', '');
			payForm.setAttribute('name', 'mobileweb');
			payForm.setAttribute('id', 'SendPayForm_id');
			payForm.setAttribute('style', 'display: none');
			payForm.setAttribute('accept-charset', 'euc-kr');
			Object.entries(array).forEach(el=>{
				var input = document.createElement('input');
				input.setAttribute('name',el[0]);
				input.setAttribute('value',el[1]);
				payForm.appendChild(input);
			})
			document.getElementsByTagName('body')[0].appendChild(payForm);
			
			popup_inicis()


			// document.getElementById("popup").style.display = "none";

			// const userinfo = await axios({
			// 	url: "/payment/userinfo",
			// 	method: "post",
			// 	headers: { "Content-Type": "application/json" },
			// })
			
			// var dt = new Date;
			// var pg = "html5_inicis"
			// var pay_method = "card"
			// var kind = 'schedule'
			// var merchant_uid = 's'+this.schedule.id.split('-')[0].replace(/_/g,'')+'_'+dt.getTime()
			// var name = this.schedule.edu_title
			// var amount = parseInt(this.selected.won.replace(/[^0-9]/g,''))
			// var buyer_name = userinfo.data.name
			// var buyer_email = userinfo.data.email
			// var buyer_tel = userinfo.data.hp
			// var buyer_addr = userinfo.data.office_addr1+' '+userinfo.data.office_addr2
			// var buyer_postcode = userinfo.data.local_code
			// var schedule_id = this.schedule.id
			// var schedule = this.schedule
			// var period = this.period
			// var m_redirect_url = "https://"+base_url

			// var array = {pg,kind,pay_method,merchant_uid,name,amount,buyer_name,buyer_email,buyer_tel,buyer_addr,buyer_postcode,schedule_id,schedule,m_redirect_url,period} 

			
			// IMP.request_pay( array , rsp => { 
			// 	if (rsp.success){
			// 		axios({
			// 			url: "/payment", 
			// 			method: "post",
			// 			headers: { "Content-Type": "application/json" },
			// 			data: {
			// 				imp_uid:rsp.imp_uid,
			// 				merchant_uid:rsp.merchant_uid,
			// 				payinfo:array
			// 			}
			// 		}).then((response) => {
			// 			if(response.data!=='ok'){
			// 				alert('수강료와 결재하신 금액이 일치하지 않습니다.\n아래 채널톡으로 당담자에게 문의하시기 바랍니다.')
			// 				location.reload()
			// 			}else{
			// 				location.reload()
			// 			}
			// 		})
			// 	}else{
			// 		alert(`결제에 실패하였습니다. ${rsp.error_msg}`);
			// 		this.close()
			// 	}
			// 	IMP.init("imp57000511"); 
			// 	document.getElementById("inner_popup").style.display = "block";

			// });

			


		},
		onClickSelectBox(){
			if(this.hideNshow=='show'){
				this.hideNshow = 'hide';
			}else{
				this.hideNshow = 'show';
			}
		},
		onSelectThis(V){
			if( V.use == this.selected.side && V.grade == this.selected.grade ){
				this.selected = V;
			}
		},
		comma(str){
			return comma2(str)
		},
		dateComma2(str){
			var nstr = str.replace(/[^0-9]/g,'')
			return `${nstr.substr(0,4)}년 ${nstr.substr(4,2)}월 ${nstr.substr(6,2)}일 ${nstr.substr(8,2)}시 ${nstr.substr(10,2)}분`
		},
		find_code_name(F,C,L){
			if(this.all_code[F+'_'+C]){
				if(L=='short'){
					return this.all_code[F+'_'+C].code_short
				}else{
					return this.all_code[F+'_'+C].code_name
				}
			}else{
				return '';
			}
		},
		cal_time(S,E){
			if(S&&E){
				var start = new Date(S)
				var end = new Date(E)
				return Math.round(((end-start) / (1000 * 60 * 60)))
			}else{
				return ''
			}
		},
	},
	
}
</script>
<style scoped>
::-webkit-scrollbar {width: 4px; height: 4px; border: 0px solid #fff; }
::-webkit-scrollbar-button:start:decrement, ::-webkit-scrollbar-button:end:increment {display: block; height: 5px; background: #efefef}
::-webkit-scrollbar-track {background: #efefef; -webkit-border-radius: 10px; border-radius:10px; -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.2)}
::-webkit-scrollbar-thumb {height: 50px; width: 50px; background: rgba(0,0,0,.2); -webkit-border-radius: 8px; border-radius: 8px; -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,.1)}
.ck_root{
	font-size: 16px;
}
#popup{
	position:absolute;
	left:0px;right:0px;bottom:0px;top:0px;
	background-color:rgba(161, 161, 161, 0.5);
	display: grid;
	align-items: center;
	justify-items:center;
	padding:10px;
	z-index:999999999999999;
	font-size:14px;
}


#inner_popup{
	max-width:1000px;
	width:90%;
	height:900px;
	background-color: #fff;
	border:1px solid #000;
	border-radius: 5px;
	box-shadow: 5px 5px 5px #000;
	z-index:9999999;
	display: grid;
	align-items: center;
	justify-items:center;
	grid-template-rows:37px 1fr;
	position: relative;
}

#contents{
	width:100%;
	height:100%;
	background-color:#fff;
	overflow-y: scroll;
	padding:10px 50px 50px 50px;
	display:grid;
	grid-template-columns: 1fr;
	grid-template-rows:100px 1fr;
}
.qrcode{
	width:200px;
	height:200px;
}

.qr_btn{
	padding:10px;
	font-size:16px;
}

@media(max-width: 500px){
	#inner_popup{
		position:absolute;
		width:auto;
		top:5px;left:5px;right:5px;bottom:5px;
		height:auto;
		padding:5px;
		padding-right:0px;
	}
	#contents{
		padding:5px 5px 5px 5px ;
	}
	.qrcode{
		width: 300px;
		height: 300px;
	}
	.qr_btn{
		padding:10px;
		font-size:16px;
	}
}

.submit_btn{
	width:120px;
	height:45px;
	border:1px solid #cccccc;
	color:#ffffff;
	background-color: #283593;
	font-size: 15px;
	cursor: pointer;
}
.hide{
	display: none;
}
.show{
	display: block;
}
/* 셀렉트 영역 스타일 */
.select{
	position: relative;
	box-sizing: border-box;
	padding: 10px 10px;
	width: 300px;
	height: 50px;
	border:1px solid #a9a9a9;
	background-image: url("https://img.icons8.com/material-rounded/24/000000/expand-arrow.png");
	background-repeat: no-repeat;
	background-position: 99% center;
	cursor: pointer;
	display: inline-block;
	margin-bottom: 20px;
	margin-top:10px;
}

/* 옵션 영역 스타일 */
.select ul{
	position: absolute;
	top: 30px;
	left: -1px;
	right: -1px;
	border:1px solid #a9a9a9;
	background-color: #fff;
	cursor: pointer;
	padding-left:0px;
	margin-bottom:100px;
}
.select ul li{
	box-sizing: border-box;
	list-style: none;
	padding: 10px;
}
.select ul li:hover{
	background-color: floralwhite;
}
.disabled{
	color: #bbbbbb;
}
fieldset{
	border: 1px solid #a9a9a9;
	border-radius: 5px;
}


</style>
