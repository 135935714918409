<template>
	<div id="main_wrap">
		<div-header :edu_code="edu_code" :all_code="all_code"></div-header>
		<div id='main'>
			<div class="sm_hidden"></div>
			<div id="main_inner">
				<router-view :edu_code="edu_code" :all_code="all_code" ></router-view>
			</div>
			<div class="sm_hidden"></div>
			<div class="footer sm_hidden"></div>
			<div-footer></div-footer>
			<div class="footer sm_hidden"></div>
		</div>

		<div class="bg" v-show="popup=='on'" style="background-color:transparent;position:absolute;left:0px;top:0px;bottom:0px;width:100%" @click="close_menu()"></div>
		<div class="bg2" v-show="popup2=='on'" style="background-color:transparent;position:absolute;left:0px;top:0px;bottom:0px;width:100%"></div>
		<div-popup :edu_code="edu_code" :all_code="all_code"></div-popup>
	</div>
</template>

<script>
import axios from 'axios';
import DivHeader from './header.vue';
import DivFooter from './footer.vue';
import DivPopup from './popup.vue';


export default {
	name: "App",
	components: {
		DivHeader,DivFooter,DivPopup
	},
	data: function () {
		return {
			path:'/',
			popup:'off',
			popup2:'off',
			edu_code:'',
			all_code:'',
			// componentKey:'',
		};
	},
	watch:{
		$route(to, from) {
			// console.log('route test')
			document.querySelector('#main').scrollTop=0;
			document.querySelector('#main').focus();
			// this.componentKey = Date.now()
		}
	},
	mounted(){
		this.emitter.on('click_schedule_page',(obj)=>{
			document.querySelector('#main').scrollTop=0;
			document.querySelector('#main').focus();
		})

		this.emitter.on('popup_menu',(obj)=>{
			this.popup=obj
			setTimeout(()=>{
				var ww = document.querySelector('#main').clientWidth
				document.querySelector('.bg').style.width = ww + 'px'
				document.querySelector('.bg2').style.width = ww + 'px'
			},100)
		})

		// this.emitter.on('popup_detail',(obj)=>{
		// 	console.log(obj)
		// 	this.popup=obj
		// 	setTimeout(()=>{
		// 		var ww = document.querySelector('#main').clientWidth
		// 		document.querySelector('.bg').style.width = ww + 'px'
		// 		document.querySelector('.bg2').style.width = ww + 'px'
		// 	},100)
		// })	

		axios
		.post('/admin/load_edu_code_def')
		.then( response=>{
			var def = {}
			response.data.forEach(el => {
				var idx2 = el.divide+"_"+el.class+el.code
				def[idx2] = el
			});
			this.edu_code = def;
		});
		axios
		.post('/admin/load_all_code')
		.then( response=>{
			var temp = {}
			response.data.forEach(el => {
				var idx = el.divide+"_"+el.code
				temp[idx] = el
			});
			this.all_code = temp;
		});

	},
	methods: {
		onClickMenu(U){
			this.path = U
			this.$router.push(U)
		},
		close_menu(){
			this.popup = 'off'
			this.emitter.emit('close_menu')
		},
	},
	
}
</script>

<style scoped>
	#main_wrap{
		position:absolute;
		left:0px;top:0px;right:0px;bottom:0px;
		overflow-x:scroll;
		overflow-y:hidden;
	}
	#main_wrap::-webkit-scrollbar {
		display: none;
	}

	#header{
		position:absolute;
		top:0px;left:0px;right:0px;height:110px;
		text-align: left;
		padding:0px 0px 0px 0px;
		display:grid;
		grid-template-columns: 1fr minmax(auto,1480px) 1fr;
		/* grid-template-columns: 1fr 1fr 1fr; */
		z-index: 2;
		border:0px;
		box-sizing: border-box;
		background-color:transparent;
	}
	
	#main{
		position:absolute;
		left:0px;top:0px;bottom:0px;right:0px;
		width:100%;
		background-color:#fff;
		text-align: left;
		padding:80px 0px 0px 0px;
		border:0px;
		border-top:76px solid #555;
		display:grid;
		grid-template-columns: 1fr minmax(auto,1300px) 1fr;
		/* grid-template-columns: 1fr 1fr 1fr; */
		grid-template-rows: 1fr auto;
		grid-column: 2 / span 3;
		box-sizing: border-box;
		margin:0px;
		overflow-y: scroll;
		overflow-x: auto;
	}
	/* #main::-webkit-scrollbar {
		display: none;
	} */
	#main_inner{
		position: relative;
		width:100%;
		/* display:grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		align-items: start;
		justify-items: center;
		overflow-y: hidden;
		overflow-x:scroll; */
	}
	/* @media (min-width:1050px) {
		#main_inner{
			width:1300px;
		}
	} */
	.footer{
		border-top:1px solid #ccc;
		background-color: #555;
	}

	/* @media(max-width: 900px){
		.sm_hidden{
			display:none;
		}
		#main,#header{
			grid-template-columns: 1fr;
		}
	}
	@media(max-width: 500px){
		.sm_hidden{
			display:none;
		}
		#main,#header{
			grid-template-columns: 1fr;
		}
	} */

</style>
