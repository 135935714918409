<template>
	<div id="footer">
		<table style="width:100%;position:relative;">
			<tr>
				<td style="white-space:pre-line">
					<img style='width:250px' src="/img/footer_logo.png" alt="">
					서울 특별시 강서구 허준로 91 (가양동 26-27) &emsp;
					<span>TEL. 1666-7575 </span>&emsp;
					<span>FAX. 02-6007-1551</span> &emsp;
					<span>사업자번호 : 204-82-02042</span> &emsp;
					<span>대표 : 윤성찬 </span>
					<br>
					<li style="list-style:none;margin-top:10px">
						COPYRIGHT AKOM.ORG ALL RIGHTS RESERVED.
					</li>
					
				</td>
			</tr>
		</table>
		
	</div>
</template>

<script>
export default {
	name: "App",
	components: {
		
	},
	data: function () {
		return {
			
		};
	},
	mounted(){
		
	},
	methods: {
	},
	
}
</script>

<style scoped>
#footer{
	border-top:1px solid #ccc;
	background-color: #555;
	padding:10px;
	color:#888;
	font-size:13px;
	height:100%;
	
}
img{
	float:right;
	position:absolute;
	right:0px;
	bottom:0px;
}
@media(max-width: 500px){
	img{
		display: none;
	}
	#footer{
		font-size: 13px;
	}
}

</style>
