<template>
	<div  style="width:100%;padding:10px;padding-bottom:50px;">
		<table border="0" style="width:100%;border:0px;" class="sm_hidden">
			<tr>
				<td style="text-align:left;border:0px;padding:0px;vertical-align:bottom;font-size:18px;font-weight:bold">
					<b style="font-size:18px"><i class="xi-calendar-list xi-x" style="vertical-align:-3px"></i>전체교육일정</b>
					<span style="font-size:15px;"> ( 온라인 보수교육포함 )</span>
				</td>
				<td style="text-align:right;border:0px;padding:0px;vertical-align:bottom;">
					
					<button v-if="show_all"  @click="onShowAll()" style="cursor:pointer" class="schedule_btn">종료 교육 제외</button>
					<button v-else  @click="onShowAll()" style="cursor:pointer" class="schedule_btn">종료 교육 보기</button>

					<select v-model="item" @change="onChangeItem()" style="width:200px;font-size:15px;margin-right:2px">
						<option value="">교육종류선택</option>
						<option v-for="item,k in item_array" :key='k' :value="item.edu_item_code">{{find_edu_name('edu_item','AK025'+item.edu_item_code)}}</option>
					</select>

					<input type="text" style="width:200px;font-size:15px;" v-model="search_term" @keyup.enter="onSearch()" placeholder="검색">
				</td>
			</tr>
		</table>
		<div class="lg_hidden">
			<p style="text-align:left;border:0px;padding:0px;vertical-align:bottom;font-size:18px;font-weight:bold">
				<b style="font-size:18px"><i class="xi-calendar-list xi-x" style="vertical-align:-3px"></i>전체교육일정</b>
				<span style="font-size:15px;"> ( 온라인 보수교육포함 )</span>
			</p>
			<button v-if="show_all"  @click="onShowAll()" style="cursor:pointer" class="schedule_btn">종료 교육 제외</button>
			<button v-else  @click="onShowAll()" style="cursor:pointer" class="schedule_btn">종료 교육 보기</button>
			<select v-model="item" @change="onChangeItem()" style="width:110px;font-size:15px;margin-right:2px">
				<option value="">교육종류선택</option>
				<option v-for="item,k in item_array" :key='k' :value="item.edu_item_code">{{find_edu_name('edu_item','AK025'+item.edu_item_code)}}</option>
			</select>
			<input type="text" style="width:140px;font-size:15px;" v-model="search_term" @keyup.enter="onSearch()" placeholder="검색">
		</div>

		<table border="0" style="margin-top:3px;table-layout:fixed;width:100%;border-top:1px solid #a9a9a9;" class="mytable">
			<colgroup>
				<col width="150px" title="상태" class="sm_hidden">
				<col width="180px" title="월" class="sm_hidden">
				<!-- <col width="210px" title="구분" class="sm_hidden"> -->
				<col title="일정명">
				<col width="80px" title="점수" class="sm_hidden">
				<col width="200px" title="구분" class="sm_hidden">
			</colgroup>
			<tr bgcolor="#eee">
				<th class="sm_hidden">상태</th>
				<th class="sm_hidden">행사일</th>
				<!-- <th class="sm_hidden">구분</th> -->
				<th>교육명칭</th>
				<th class="sm_hidden">연상한평점<br>일반(필수)</th>
				<th class="sm_hidden">구분</th>
			</tr>
			<tr v-for='all,k in all_schedules' :key="k" :style="{opacity:toYMDHS>all.web_close_ymdhs?'.5':'1','background-color':all.edu_item_code=='0008'?'#FFFDE7':'#fff'}" class="ltr" v-show='show_all||(!show_all&&toYMDHS<=all.web_close_ymdhs)'>
				<td class="sm_hidden">
					<span v-if="all.my_reg&&all.edu_ok_yn">
						<button v-if="all.edu_item_code=='0007'" class=todo style="color:gray" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)">제출완료</button>
						<button v-else-if="all.edu_item_code=='0008'||all.edu_item_code=='0012'" class=todo style="color:blue" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)" >수강중</button>
						<span v-else class=todo style="color:gray" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)"><i class="xi-school xi-x"></i>수강완료</span>
					</span>
					<span v-else-if="all.my_reg&&!all.edu_ok_yn&&toYMDHS>all.web_close_ymdhs">기한종료</span>
					<span v-else-if="all.my_reg&&!all.edu_ok_yn&&toYMDHS<all.web_open_date" style="font-size:13px;line-height:1">사전등록<br>완료</span>
					<span v-else-if="all.my_reg&&!all.edu_ok_yn&&toYMDHS>=all.web_open_date">
						<button v-if="all.edu_ok_yn" class=todo style="color:blue" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)" >수강중</button>
						<button v-else class=todo style="color:blue" @click="onClickSchedule(k)" >수강중</button>
					</span>
					<span v-else-if="toYMDHS>all.web_close_ymdhs">행사종료</span>
					<span v-else-if="!all.my_reg&&all.pre_reg_start&&toYMDHS>=all.pre_reg_start&&toYMDHS<all.pre_reg_end" style="color:orange;font-weight:bold;">
						사전등록중
						<span v-if="all.pre_reg_start" style="color:gray;font-size:13px"><br>( {{dateComma(all.pre_reg_start.substr(0,8))}}<br>~ {{dateComma(all.pre_reg_end.substr(0,8))}} )</span>
					</span>
					<span v-else-if="!all.my_reg&&toYMDHS>=all.web_open_date&&toYMDHS<=all.web_close_ymdhs" style="color:red">진행중</span>
					<span v-else style="color:green">준비중</span>
				</td>
				<td class="sm_hidden">
					
					<span v-if="all.edu_place=='AKOM교육센터'" style="color:red">온라인</span>
					<span v-else-if="pick_week(all.web_open_date,all.web_close_date)" style="font-size:16px;">
						오프라인<br>
						{{parseInt(all.web_open_date.substr(4,2))}}월{{all.web_open_date.substr(6,2)}}일 <span v-html="pick_week(all.web_open_date,all.web_close_date)"></span>
						<br>
						<span v-if="all.web_open_time" style="color:#a9a9a9;font-size:13px;">
							{{all.web_open_time.substr(0,2)}}:{{all.web_open_time.substr(2,2)}}~{{all.web_close_time.substr(0,2)}}:{{all.web_close_time.substr(2,2)}}
						</span>
					</span>
					<span v-else-if="all.edu_place!=='AKOM교육센터'">
						오프라인<br>
						{{parseInt(all.web_open_date.substr(4,2))}}월{{all.web_open_date.substr(6,2)}}일 ~ {{parseInt(all.web_close_date.substr(4,2))}}월{{all.web_close_date.substr(6,2)}}일
					</span>
					<span v-else style="color:red">온라인</span>
					
				</td>
				<!-- <td class="sm_hidden">
					{{find_edu_name('edu_item','AK025'+all.edu_item_code)}}
				</td> -->
				<td style='text-align:left;padding-left:20px;line-height:1.5;height:120px;' @click="onClickSchedule(k)">
					<div class="lg_hidden" style="padding-top:10px;padding-bottom:10px" >
						<span v-if="all.my_reg&&all.edu_ok_yn">
							<button v-if="all.edu_item_code=='0007'" class=todo style="color:gray" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)">제출완료</button>
							<button v-else-if="all.edu_item_code=='0008'||all.edu_item_code=='0012'" class=todo style="color:blue" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)" >수강중</button>
							<span v-else class=todo style="color:gray" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)"><i class="xi-school xi-x"></i>수강완료</span>
						</span>
						<span v-else-if="all.my_reg&&!all.edu_ok_yn&&toYMDHS>all.web_close_ymdhs">기한종료</span>
						<span v-else-if="all.my_reg&&!all.edu_ok_yn&&toYMDHS<all.web_open_date">사전등록완료</span>
						<span v-else-if="all.my_reg&&!all.edu_ok_yn&&toYMDHS>=all.web_open_date">
							<button v-if="all.edu_ok_yn" class=todo style="color:blue" @click="goSchedule(all.id,toYMDHS>all.web_close_ymdhs)" >수강중</button>
							<button v-else class=todo style="color:blue" @click="onClickSchedule(k)" >수강중</button>
						</span>
						<span v-else-if="all.edu_item_code!=='0002'&&!all.my_reg&&all.pre_reg_start&&toYMDHS>=all.pre_reg_start&&toYMDHS<all.pre_reg_end" style="color:red">
							사전등록중
							<span v-if="all.pre_reg_start" style="color:gray;font-size:13px">
								<br>
								( {{dateComma(all.pre_reg_start.substr(0,8))}}
								~ {{dateComma(all.pre_reg_end.substr(0,8))}} )
							</span>
						</span>
						<span v-else-if="!all.my_reg&&toYMDHS>=all.web_open_date&&toYMDHS<=all.web_close_ymdhs" style="color:red">진행중</span>
						<span v-else-if="toYMDHS>all.web_close_ymdhs">행사종료</span>
						<span v-else style="color:green">준비중</span>
					</div>
				

					<div class="lg_hidden">
						{{find_edu_name('edu_item','AK025'+all.edu_item_code)}}
					</div>


					<a @click="onClickSchedule(k)" style="font-size:17px"><b>{{all.edu_title}}</b><i class="xi-lock xi-fw" v-if="status=='clone'&&all.hidden=='1'"></i></a>
					<br>
					<li class='info' style="width:260px;color:#000">
						<span v-if="pick_week(all.web_open_date,all.web_close_date)">
							시간 : 
							<span class="lg_hidden" >{{dateComma(all.web_open_date)}}</span>
							<span class="lg_hidden" v-html="pick_week(all.web_open_date,all.web_close_date)"></span>
							<!-- &nbsp; -->
							<span v-if="all.web_open_time">
								{{all.web_open_time.substr(0,2)}}:{{all.web_open_time.substr(2,2)}} ~ {{all.web_close_time.substr(0,2)}}:{{all.web_close_time.substr(2,2)}}
							</span>
						</span>
						<span v-else>기간 : {{dateComma(all.web_open_date)}} ~ {{dateComma(all.web_close_date)}} </span>
					</li>
					
					<li class='info' :style="{color:all.edu_place!=='AKOM교육센터'?'#3366cc':'#FA8072'}">
						장소 : {{all.edu_place}}<span>{{all.edu_place=='AKOM교육센터'?'(온라인)':''}}</span>
					</li>
					<li class='info' >
						주관 : {{find_edu_name('edu_organ',all.edu_organ_code)}}&emsp;{{all.tel!=='undefined'?all.tel:''}}
					</li>

					<li class="info lg_hidden">
						<span v-if="all.id.indexOf('2019_0022_AK0260131_428')>-1">{{all.edu_time_point}}시간</span>
						<span v-else>평점 : {{all.edu_time_point}} ({{all.id.indexOf('0008_AK0010000')>-1?'~':all.edu_must_point}})</span> 
					</li>
					<!-- <li class="info lg_hidden">{{find_edu_name('edu_organ',all.edu_organ_code)}} {{all.tel}}</li> -->
					<br class="lg_hidden">
				</td>
				<td class="sm_hidden">
					<span v-if="all.id.indexOf('2019_0022_AK0260131_428')>-1">{{all.edu_time_point}}시간</span>
					<span v-else>{{all.edu_time_point}} ({{all.id.indexOf('0008_AK0010000')>-1?'~':all.edu_must_point}})</span> 
				</td>
				<td class="sm_hidden">{{find_edu_name('edu_item','AK025'+all.edu_item_code)}}</td>
			</tr>
		</table>


		<div id='안내' style="padding-top:100px;" class="sm_hidden">
			<ul class="tab_ul2">
				<li class="tab2" :class="{selected:selected_tab=='portal'}" @click="onSelectTab('portal')">보수교육</li>
				<li class="tab2" :class="{selected:selected_tab=='insure'}" @click="onSelectTab('insure')">보험교육</li>
				<li class="tab2" :class="{selected:selected_tab=='law'}" @click="onSelectTab('law')">법정교육</li>
			</ul>
			<div class='ck_root' style="width:100%;min-height:1200px;padding-top:10px" v-html="portal"></div>
		</div>

		<div class="bg" v-show="popup=='on'" 
			style="opacity:.5;background-color:#fff;position:absolute;left:0px;top:0px;height:800px;width:100%;display:grid;align-items:center;justify-items:center;"
		>
		<i class="xi-spinner-2 xi-spin xi-3x"></i>
		</div>
	</div>
</template>

<script>

var dt = new Date
var yyyy = dt.getFullYear();
var mm = ("0" + (1 + dt.getMonth())).slice(-2);
var dd = ("0" + dt.getDate()).slice(-2);
var hh = ("0" + dt.getHours()).slice(-2);
var ii = ("0" + dt.getMinutes()).slice(-2);
var today = yyyy + mm + dd + hh + ii
import axios from 'axios';

export default {
	props:['edu_code'],
	name: "App",
	components: {
		
	},
	data: function () {
		return {
			show_all:false,
			portal:'',
			all_schedules:'',
			toYMD:dt.getFullYear()+('0'+(dt.getMonth()+1)).slice(-2)+('0'+(dt.getDate())).slice(-2),
			toYMDHS:dt.getFullYear()+('0'+String(dt.getMonth()+1)).slice(-2)+('0'+String(dt.getDate())).slice(-2)+('0'+String(dt.getHours())).slice(-2)+('0'+String(dt.getMinutes())).slice(-2),
			item_array:'',
			item:'',
			search_term:'',
			selected_tab:'portal',
			popup:'off',
			status:status,
			week:['일', '월', '화', '수', '목', '금', '토']
		};
	},
	mounted(){
		axios
		.post('/edu/load_item_group')
		.then(response=>{
			this.item_array = response.data
		})
		axios
		.post('/edu/load_portal')
		.then( response=>{
			this.portal = response.data.contents
		});
		// axios
		// .post('/edu/load_edu_code_def')
		// .then( response=>{
		// 	var temp2 = {}
		// 	response.data.forEach(el => {
		// 		var idx2 = el.divide+"_"+el.class+el.code
		// 		temp2[idx2] = el
		// 	});
		// 	this.edu_code = temp2;
		// });
		this.load_all_schedule()
	},
	methods: {
		onSelectTab(F){
			this.portal = ''
			this.selected_tab = F
			axios
			.post('/edu/load_portal',{
				id:F
			})
			.then( response=>{
				this.portal = response.data.contents
			});
		},
		onChangeItem(){
			this.search_term=''
			this.show_all = true
			this.load_all_schedule()
		},
		onSearch(){
			this.show_all = true
			this.load_all_schedule()
		},
		load_all_schedule(){
			axios
			.post('/open/load_all_schedule',{
				year:dt.getFullYear(),
				item:this.item,
				search_term:this.search_term
			})
			.then((response)=>{
				this.all_schedules = response.data;
			});
		},
		onShowAll(){
			this.show_all = this.show_all==false?true:false
		},
		goSchedule(I,F){
			if(F){
				alert('종료된 행사입니다.')
				return false
			}else{
				this.$router.push('/schedule?sch='+I)
			}
			
		},
		onClickSchedule(K){

			location.href = '/home'
			return false

			var close_time = this.all_schedules[K].web_close_date+this.all_schedules[K].web_close_time||'0000'

			// if(String(today).substring(0,8)>String(close_time).substring(0,8)){
			// 	alert('기한이 종료된 보수교육입니다.')
			// 	return false
			// }

			if(this.all_schedules[K].my_reg==1 && this.all_schedules[K].edu_place=='AKOM교육센터' && close_time > this.toYMDHS ){
				this.$router.push('/schedule?sch='+this.all_schedules[K].id)
				return false
			}
			if(this.all_schedules[K].hidden==1){
				alert('노출이 제한된 보수교육입니다. 자세한 사항은 행사주관기관에 문의하시기 바랍니다.')
				return false;
			}
			this.popup = 'on'
			axios
			.post('/edu/whoami_check',{
				schedule:this.all_schedules[K]
			}).then(response=>{
				
				if(response.data=='failed'){location.reload()}

				var opt_cnt = 0;
				JSON.parse(this.all_schedules[K].price)?.forEach(el => {
					if(el.use!=='false'){
						opt_cnt++
					}
				});
				var selected = response.data
				var schedule = this.all_schedules[K]
				
			})

			this.emitter.emit('popup_schedule_info',{schedule})
			this.popup = 'off'
		},
		dateComma(str){
			return dateComma(str,'.')
		},
		pick_week(A,B){
			var a = dateComma(A,'-')
			var b = dateComma(B,'-')
			var day1 = new Date(a);
			var day2 = new Date(b);
			var difference = day2.getTime()-day1.getTime();

			// console.log(A,B,difference)

			// if( (difference/(1000*3600*24)+1) >60){
			if(A!==B){
				return false
			}else{
				// return difference<0?'오류':difference/(1000*3600*24)+1+'일간'
				if(day1.getDay()==0){
					return '(<span style=color:red>'+this.week[day1.getDay()]+'</span>)'
				}else if(day1.getDay()==6){
					return '(<span style=color:blue>'+this.week[day1.getDay()]+'</span>)'
				}else{
					return '('+this.week[day1.getDay()]+')'
				}
			}
		},
		cal_period(A,B){
			var a = dateComma(A,'-')
			var b = dateComma(B,'-')
			var day1 = new Date(a);
			var day2 = new Date(b);
			var difference = day2.getTime()-day1.getTime();

			if( (difference/(1000*3600*24)+1) >365){
				return '상시'
			}else{
				return difference<0?'오류':difference/(1000*3600*24)+1+'일간'
			}


			
		},
		find_edu_name(F,C){
			if(this.edu_code[F+'_'+C]){
				return this.edu_code[F+'_'+C].code_name
			}else{
				return '';
			}
		},
	},
	
}
</script>

<style scoped>

.ltr:hover{
	background-color: #f0f0f0;
}
figure.table{
	width:100%;	
}
@media(min-width:501px){
	.lg_hidden{
		display:none;
	}
}
@media(max-width: 500px){
	.sm_hidden{
		display: none;
	}
}
/* 
.schedule_div{
	margin:10px;
	padding:10px 100px 50px 100px;
	display:grid;
	align-items: center;
}

.schedule_div th{
	border-bottom: 1px solid #a9a9a9;
	font-weight:normal;
	text-align: center;
	padding:10px;
	height: 30px;
}
.schedule_div td{
	border-bottom: 1px solid #a9a9a9;
	white-space:nowrap;overflow:hidden;text-overflow:ellipsis;
	padding:15px;
	height: 30px;
	text-align: center;
	font-size: 15px;
}
a{
	cursor: pointer;
}
a:hover{
	text-decoration: underline;
}

select{
	margin:0px;
	margin-right: 2px;
	font-family:'Play','Noto Sans KR';
}
li{
	list-style: circle;
	color:#555;
	font-size:14px;
	margin-left:20px;
	display: inline-block;
	margin-top: 10px;
}
td{
	padding:5px;
} */


</style>
