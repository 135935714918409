<template>
	<div class="inner" style="min-width:1100px;padding:10px">
		<div>
			<table style="table-layout:fixed;width:100%;">
				<tr>
					<td style="text-align:left;">
						<b style="font-size:18px" v-if="categ=='notice'"><i class="xi-bell-o xi-x" style="vertical-align:-3px"></i>공지사항</b>
						<b style="font-size:18px" v-if="categ=='FAQ'"><i class="xi-help-o xi-x" style="vertical-align:-3px"></i>FAQ</b>
						<b style="font-size:18px" v-if="categ=='PDS'"><i class="xi-attachment xi-x" style="vertical-align:-3px"></i>자료실</b>
					</td>
					<td style="text-align:right;">
						<select style="font-size: 15px;" @change="onchange_categ2">
							<option value="">교육별로보기</option>
							<option>보수교육</option>
							<option>보험교육(추나,첩약)</option>
							<option>법정교육</option>
						</select>
						<input type="text" v-model="search_term" style="margin-left:2px;margin-right:5px"  @keyup.enter="search()">
						<i class="xi-search xi-x" @click="search()" style="vertical-align:-10px"></i>

					</td>
				</tr>
			</table>
			
			<div v-if="select">
				<table style="table-layout:fixed;width:100%;margin-bottom:100px;margin-top:5px" >
					<tr bgcolor="#eee">
						<td style='padding:0px;height:50px;border-top:1px solid #a9a9a9;border-bottom:1px solid #a9a9a9'>
							<table style='width:100%'>
								<tr>
									<td style='text-align:left;border:0px;width:80px'>No.{{contents.seq}}</td>
									<td style='text-align:left;border:0px;font-size:18px;font-weight:bold' v-html="contents.title"></td>
									<td style='text-align:right;border:0px'>작성자 : {{contents.team}}</td>
									<td style='text-align:right;border:0px'>{{contents.create_date?.substr(0,10)}}</td>
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td style="border-bottom:0px;">
							<div class='ck_root' style="width:1200px;min-height:200px;padding-top:10px;overflow-x:auto;" v-html="contents?.contents"></div>
						</td>
					</tr>
					<tr v-if="contents.uploaded">
						<td style="border-top:1px solid #fff;text-align:right;" >
							<li v-for="file,k in JSON.parse(contents.uploaded)" :key="k" style="list-style:none;margin-bottom:5px;">
								<a @click="onDownload(select,k)"><i class="xi-save xi-fw"></i>{{file.originalname}}</a>
							</li>
						</td>
					</tr>
					<tr>
						<td style="border-bottom:0px;text-align:left">
							<button @click="onSelect()">목록</button>
						</td>
					</tr>
				</table>
			</div>

			<table style="table-layout:fixed;width:100%;margin-top:5px" class="mytable">
				<colgroup>
					<col width="30px" title="alert">
					<col width="50px" title="번호">
					<col width="150px" title="번호">
					<col title="제목">
					<col width="180px" title="팀">
					<col width="180px" title="등록일">
				</colgroup>
				<tr bgcolor="#eee">
					<th></th>
					<th>번호</th>
					<th>구분</th>
					<th>제목</th>
					<th>작성자</th>
					<th>등록일</th>
				</tr>
				<tr v-for="list,k in list_array" :key="k" class="ltr" :class="{'curr':select==list.id}">
					
					<td style="text-align:right;"><img src='/img/new.gif' v-if="categ=='notice'&&list.readed==0&&list.id>1851"></td>
					<td>{{list.seq}}</td>
					<td>{{list.categ2}}</td>
					<td style="text-align:left;padding-left:20px"><a @click="onSelect(list.id,k)" v-html="list.title"></a></td>
					<td>{{list.team}}</td>
					<td>{{list.create_date?.substr(0,10)}}</td>
				</tr>
			</table>

			<div style="text-align:center;padding:10px">
				<button class="page_btn"  @click="onClickpage(Math.floor(page/10)*10-1)" v-if="Math.floor(page/10)!==0"><i class="xi-angle-left-min xi-x"></i></button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10)==page}" @click="onClickpage(Math.floor(page/10)*10)" v-if="Math.floor(page/10)!==0" >{{Math.floor(page/10)||''}}0</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+1)==page}" @click="onClickpage(Math.floor(page/10)*10+1)" v-if="(Math.floor(page/10)*10+1)<=totalpage">{{Math.floor(page/10)||''}}1</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+2)==page}" @click="onClickpage(Math.floor(page/10)*10+2)" v-if="(Math.floor(page/10)*10+2)<=totalpage">{{Math.floor(page/10)||''}}2</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+3)==page}" @click="onClickpage(Math.floor(page/10)*10+3)" v-if="(Math.floor(page/10)*10+3)<=totalpage">{{Math.floor(page/10)||''}}3</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+4)==page}" @click="onClickpage(Math.floor(page/10)*10+4)" v-if="(Math.floor(page/10)*10+4)<=totalpage">{{Math.floor(page/10)||''}}4</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+5)==page}" @click="onClickpage(Math.floor(page/10)*10+5)" v-if="(Math.floor(page/10)*10+5)<=totalpage">{{Math.floor(page/10)||''}}5</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+6)==page}" @click="onClickpage(Math.floor(page/10)*10+6)" v-if="(Math.floor(page/10)*10+6)<=totalpage">{{Math.floor(page/10)||''}}6</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+7)==page}" @click="onClickpage(Math.floor(page/10)*10+7)" v-if="(Math.floor(page/10)*10+7)<=totalpage">{{Math.floor(page/10)||''}}7</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+8)==page}" @click="onClickpage(Math.floor(page/10)*10+8)" v-if="(Math.floor(page/10)*10+8)<=totalpage">{{Math.floor(page/10)||''}}8</button>
				<button class="page_btn" :class="{'curr':(Math.floor(page/10)*10+9)==page}" @click="onClickpage(Math.floor(page/10)*10+9)" v-if="(Math.floor(page/10)*10+9)<=totalpage">{{Math.floor(page/10)||''}}9</button>
				<button class="page_btn" @click="onClickpage(Math.floor(page/10)*10+10)" v-if="(Math.floor(page/10)*10+10)<=totalpage"><i class="xi-angle-right-min xi-x"></i></button>
			</div>

		</div>
	</div>
</template>

<script>

import axios from 'axios';

export default {
	// props:['edu_code','categ'],
	name: "App",
	components: {
	},
	data: function () {
		return {
			list_array:'',
			select:'',
			idx:'',
			path:'',
			categ:'',
			contents:'',
			totalpage:'',
			page:1,
			perpage:15,
			search_term:'',
			categ2:''
		};
	},
	mounted(){
		this.categ = this.$route.path.replace('/','')

		if(this.categ=='PDS'&&!license){
			location.href='/home'
		}

		this.categ2 = ''
		this.select= this.$route.query.id
		this.load_notice()
	},
	watch:{
		$route(to, from) {
			this.categ2 = ''
			this.categ = to.path.replace('/','')
			this.select=to.query.id
			this.load_notice()
		}
	},
	methods: {
		onchange_categ2(e){
			this.page = 1
			this.categ2 = e.target.value
			this.load_notice()
		},
		onClickpage(P){
			this.select = ''
			this.contents = ''
			this.page = P
			this.load_notice()
		},
		onDownload(S,K){
			var obj = this.list_array.find(el=>el.id==S)
			var file = JSON.parse(obj.uploaded)[K]
			// console.log(JSON.parse(obj.uploaded))
			location.href ='/download/?fn='+file.filename+'&ori='+encodeURIComponent(file.originalname)
		},
		onSelect(S,K){
			if(!S&!K){
				this.$router.push('/'+this.categ)
			}
			// axios.post('/open/read_notice',{
			// 	id:S
			// })
			// .then(response=>{
			// 	// this.load_notice()
			// 	this.emitter.emit('modify_alert')
				
			// })
			this.emitter.emit('modify_alert')
			this.$router.push({name:this.categ,query:{id:S,k:K}})
			
		},
		search(){
			this.select = ''
			this.page = 1
			this.load_notice()
		},
		load_notice(){

			document.querySelector('#main').scrollTop = '0px'
			this.list_array = ''
			this.contents = ''
			axios.post('/open/load_notice',{
				categ:this.categ,
				categ2:this.categ2,
				page:this.page,
				perpage:this.perpage,
				search_term:this.search_term
			})
			.then(response=>{
				this.list_array = response.data
				
				this.totalCnt = response.data[0]?.totalCnt||0
				if(response.data.length>0){
					this.totalpage = Math.ceil(response.data[0].totalCnt/this.perpage)
				}else{
					this.totalpage = 0
				}


				if(this.select){
					this.contents = response.data.find(el=>el.id == this.select)
				}

			})
		}
	},
	
}
</script>

<style scoped>

.page_btn{
	min-width:30px;
	padding:4px;
	margin:1px;
	border:1px solid #a9a9;
}
.page_btn.curr{
	background-color: yellowgreen;
}

/* .ck_root img{
	width:50%;
}*/

.ck_root{
	width:100%;
	font-size: 15px;
	text-align: left;
	box-sizing:border-box;
	white-space:pre-wrap; 
	word-wrap:break-word; 
	word-break: break-all;
	padding:10px 20px;
}
.ck_root p{
	word-wrap:break-all;
	text-align:left;
}

.curr{
	background-color: yellowgreen;
}
.ck_root .table{
	table-layout:fixed;
}
.ck_root .table p,
.ck_root .table td{
	word-break:break-all;
	word-wrap:break-word;
	text-align:left;
}
.ltr:hover{
	background-color:#f0f0f0;
}


/* .ck_root .table table{
	width:500px;
}



 */

/*

a{text-decoration: none;cursor: pointer;color:#000}
a:visited{text-decoration: none;color:#000}
a:hover{text-decoration:underline;color:#3445a8;}
a:link{text-decoration: none;color:#000}

.alert{
	background-color: red;
	color:#ffffff;
	font-weight: bold;
	border:1px solid red;
	border-radius: 20px;
	margin-top:-20px;
	margin-left:-10px;
	float:right;
	padding:0px;
	height:10px;
	width:10px;
	font-size:12px;
	text-align:center;
	z-index:999999999999;
} */
</style>
